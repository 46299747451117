body {
  font-family: Arial, sans-serif !important;
  background-color: #f8f8f8;
}


.css-1qkubha {
  background-color: #f8f8f8 !important;
}

.MuiContainer-root {
  background-color: #f8f8f8 !important;
}

.MuiInputBase-root {
  background-color: white !important;
}

.MuiListItemButton-gutters::after {
  width: 100% !important;
  background-color: #b21b77 !important;
  color: white !important;
  border-radius: 0px !important;
}

.MuiListItemButton-root {
  color: #49213b !important;
  border-radius: 0px !important;
}


.MuiInput-input:focus {
  border-color: #b21b77 !important
}


.css-fd1sk1-MuiButtonBase-root-MuiListItemButton-root {
  background-color: #b21b77 !important;
  color: white !important;
  border-radius: 0px !important;
}

.MuiButton-text,
.MuiButton-contained {
  border-radius: 5px;
  background-color: white !important;
  color: #b21b77 !important;
  border-color: #b21b77 !important;
  border: 2px solid !important;
}

.MuiButton-contained::selection {
  color: white !important;
}

.Mui-disabled {
  background: #e9e9e9 !important;
  border: #e9e9e9 !important;
  color: #909090 !important;
  cursor: not-allowed;
}

.MuiButton-contained:hover {
  background-color: #b21b77 !important;
  color: white !important;
  border-color: #b21b77 !important;
  border: 2px solid !important;
}

.MuiButtonBase-root:hover {
  background-color: #b21b77 !important;
  color: white !important;
}